export const chipColors = {
    methods: {
        color(item){
            switch (item) {
				case 'Oczekujące':
					if (this.role == 'administrator' || this.role == 'moderator'){
						return 'yellow'
					} else if (this.role == 'korektor'){
						return 'black'
					}else {
						return 'red'
					}
                case 'Odrzucone':
                    return 'red'
                case 'Przyjęte':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'black'
					}else  if (this.role == 'korektor') {
						return 'yellow'
					} else {
						return 'yellow'
					}
                case 'Do sprawdzenia':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'black'
					} else if (this.role == 'korektor') {
						return 'red'
					} else {
						return 'green'
					}
                case 'W trakcie sprawdzania':
					if(this.role == 'korektor') {
						return 'red'
					} else {
						return 'yellow'
					}
                case 'Sprawdzone':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'red'
					} else {
						return 'green'
					}
                case 'Wysłane':
					if(this.role == 'korektor') {
						return 'green'
					} else {
						return 'yellow'
					}	
                case 'Do poprawki':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'black'
					} else if (this.role == 'korektor') {
						return 'yellow'
					}else {
						return 'red'
					}
                case 'Poprawione':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'yellow'
					} else if (this.role == 'korektor') {
						return 'red'
					}else {
						return 'green'
					}
                case 'Ponownie poprawione':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'red'
					}else {
						return 'gray'
					}
                case 'Zaakceptowane':
                    return 'green'
				case 'Zakończone':
					return 'green'
                case 'Anulowane':
                    return 'black'
                default:
                    return 'gray'
            }
		},
		fontColor(status){
			switch(status){
				case 'Oczekujące':
					if (this.role == 'korektor') {
						return 'black'
					} else {
						return 'white'
					}
				case 'Odrzucone':
					return 'white'
				case 'Przyjęte':
					return 'black'
				case 'Do sprawdzenia':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'black'
					} else {
						return 'white'
					}
				case 'W trakcie sprawdzania':
					return 'white'
				case 'Sprawdzone':
					return 'white'
				case 'Wysłane':
					return 'white'
				case 'Do poprawki':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'black'
					} else {
						return 'white'
					}
                case 'Ponownie poprawione':
					if (this.role == 'administrator' || this.role == 'moderator') {
						return 'white'
					}else {
						return 'black'
					}
				case 'Poprawione':
					return 'white'
				case 'Zaakceptowane':
					return 'white'
				case 'Zakończone':
					return 'white'
				case 'Anulowane':
					return 'white'
				default:
					return 'gray'
				}
			},
	},
	computed: {
		role(){
			return this.$store.getters.getUserRole;
		}
	},
}
