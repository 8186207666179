<template>
    <div>
        <v-dialog v-model="modal" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Dodaj komentarz</span>
                    <span
                        @click="modal = !modal"
                        class="ml-auto cursor-pointer"
                        >X</span
                    >
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">

                        <VueEditor v-model="description" />

                    </v-form>
                </v-card-text>

                <v-card-actions class="ml-2">
                    <v-btn
                        color="primary"
                        @click="addComment"
                        :loading="isLoading"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-container>
            <v-layout row class="my-4 mt-6">

                <v-card>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">
                                {{ clientInfo.client.type === 0 ? "Firma" : "Osoba Fizyczna" }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Typ</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

                <v-card class="ml-3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">{{
                                clientInfo.client.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>Nazwa</v-list-item-subtitle
                            >
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="clientInfo.client.name"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <!-- <pre>{{ clientInfo.client }}</pre> -->

                <v-card class="ml-3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">{{
                                clientInfo.client.email
                            }}</v-list-item-title>
                            <v-list-item-subtitle>Email</v-list-item-subtitle
                            >
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="clientInfo.client.email"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

                <v-card class="ml-3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">{{
                                clientInfo.client.nip
                            }}</v-list-item-title>
                            <v-list-item-subtitle>NIP/Adres</v-list-item-subtitle
                            >
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="clientInfo.client.nip"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

                <v-card class="ml-3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">{{
                                clientInfo.client.date.split("T")[0]
                            }}</v-list-item-title>
                            <v-list-item-subtitle>Dodano</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

            </v-layout>

            <v-layout row class="mt-6">

                <v-row no-gutters v-if="clientInfo.client.comment">
                    <v-col
                        cols="10"
                    >
                        <v-card>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title class="mt-3" v-html="clientInfo.client.comment"></v-list-item-title>
                                    <v-list-item-subtitle>Komentarz</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-btn color="blue white--text" @click="modal = !modal">Edytuj</v-btn>
                    </v-col>
                </v-row>

                <v-row no-gutters v-else> 
                    <v-btn @click="modal = !modal">Dodaj komentarz</v-btn>
                </v-row>

            </v-layout>

            <v-layout row class="my-4 mt-6">

                <v-data-table
                    :loading="clientInfo.tasks"
                    :headers="headers"
                    :items="clientInfo.tasks"
                    class="elevation-2 mt-3"
                    :sort-desc="true"
                    :sort-by="['date']"
                    item-key="id"
                    :items-per-page="15"
                    :search="search"
                >
                    <template v-slot:top>
                        <v-text-field
                        v-model="search"
                        label="Szukaj"
                        class="mx-4"
                        ></v-text-field>
                    </template>
                    <template v-slot:item.show="{ item }">
                        <v-btn 
                            style="min-width: 35px; padding: 0;"
                            color="green"
                            class="mt-1 mb-1 mr-2 white--text"
                            @click.stop="changeRoute(item._id)"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" style="max-width: 20px; rotate: 180deg;" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 476.213 476.213" xml:space="preserve">
                                <polygon points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5   57.427,253.107 476.213,253.107 "/>
                            </svg>
                        </v-btn>
                    </template>

                    <template v-slot:item.copywriterId="{ item }">
                        <div :class="{ opacity: item.copywriterIsbanned }">
                            {{ item.copywriterId?.name ? item.copywriterId.name : "" }}
                        </div>
                    </template>

                    <template v-slot:item.pmId="{ item }">
                        {{ item.pmId?.name ? item.pmId.name : "" }}
                    </template>

                    <template v-slot:item.korektor="{ item }">
                        <div>
                            {{ item.korektorId?.name ? item.korektorId.name : "" }}
                        </div>
                    </template>

                    <template v-slot:item.date="{ item }">
                        <span :style="`color: ${item.copyLate ? 'red' : ''}`">{{
                            item.date ? item.date.split("T")[0] : ""
                        }}</span>
                    </template>

                    <template v-slot:item.dateCp="{ item }">
                        {{ item.dateCp ? item.dateCp.split("T")[0] : "" }}
                        {{ correctDate(item.dateCp) }}
                    </template>

                    <template v-slot:item.copyRate="{ item }">
                        {{ item.copyRate ? item.copyRate.toFixed(2) : "" }}
                    </template>

                    <template v-slot:item.fullRate="{ item }">
                        {{ item.fullRate ? item.fullRate.toFixed(2) : "" }}
                    </template>
                    <template v-slot:item.corectorRate="{ item }">
                        {{
                            item.corectorRate
                                ? (
                                        (item.corectorRate * item.characters) /
                                        1000
                                    ).toFixed(2)
                                : ""
                        }}
                    </template>

                    <template v-slot:item.status="{ item }">
                        <StatusChip :status="item.status" />
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="grey lighten-2"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mt-1 mb-1 mr-1 black--text"
                                    fab
                                    small
                                >
                                ...
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                <v-btn 
                                    style="padding: 0 5px;"
                                    color="green"
                                    class="mt-1 mb-1 mr-1 white--text"
                                    @click.stop="duplicateTask(item)"
                                    v-on="on"
                                >Kopiuj</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    
                </v-data-table>

                <!-- <div 
                    v-for="(item, index) in clientInfo.tasks"
                    :key="index"
                ></div> -->

                <!-- <v-flex md8>
                    <v-card
                        height="600"
                        class="scroll mr-10 py-6 px-6 text-left mt-3"
                        v-if="clientInfo.tasks"
                    >
                        Zadania
                        
                             {{ item }} -->
                            <!-- {{ item.name }}
                            {{ item.name }} -->
                        <!-- </div> -->
                        <!-- {{ clientInfo }}clientInfo -->
                        <!-- notatka do klienta -->
                        <!-- bug w edycji po wyszukaniu -->
                    <!-- </v-card> -->
                <!-- </v-flex> --> 
            </v-layout>
        </v-container>
    </div>
</template>

<script>
const token = localStorage.getItem('user');
import StatusChip from "@/components/molecules/StatusChip.vue";
import { VueEditor } from 'vue2-editor';
import io from "socket.io-client";

var connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
};

export default {
    data(){
        return{
            socket: io(this.url, connectionOptions),
			clientInfo: [],
            search: '',
            headers: [
                { text: "Podgląd", value: "show", sortable: false },
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Deadline copywriter",
                    value: "dateCp",
                    sortable: true,
                },
                { text: "Deadline", value: "date", sortable: true },
                { text: "Copywriter", value: "copywriterId", sortable: true },
                { text: "PM", value: "pmId", sortable: true },
                { text: "Korektor", value: "korektor", sortable: true },
                {
                    text: "Stawka copywritera",
                    value: "copyRate",
                    sortable: true,
                },
                { text: "Stawka", value: "fullRate", sortable: true },
                {
                    text: "Stawka korektora",
                    value: "corectorRate",
                    sortable: true,
                },
                { text: "Znaki", value: "characters", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "Akcje", value: "actions" },
            ],
            modal: false,
            description: '',
            isLoading: false
            // headers: [
            //     { text: 'Typ', value: 'type', align: 'left', sortable: true },
            //     { text: 'Nazwa', value: 'name', sortable: true },
            //     { text: 'Dodano', value: 'date', sortable: true },
            //     { text: 'NIP/Adres', value: 'nip', sortable: true },
            //     { text: 'Email', value: 'email', sortable: true },
            //     { text: 'Akcje', value: 'actions', sortable: false },
            // ]
        }
	},
    components: {
        StatusChip,
        VueEditor
    },
    methods:{
        sendFile(taskId) {
            if (this.file != null) {
                let formData = new FormData();

                formData.append("file", this.file);
                formData.append("id", taskId);
                formData.append("emmit", false);
                this.$axios
                    .post(this.url + "api/uploads", formData, { headers: { 'x-access-token': token } })
                    .then(() => {
                        this.file = null;
                    });
            }
        },
        duplicateTask(task) {
            const statusName = 'Szkic';
            const dateCpp = new Date(task.date);
            const formattedDate = dateCpp.toISOString().split('T')[0];

            console.log(task)

            this.$axios
                .post(`${this.url}api/tasks`, {
                    name: task.name,
                    description: task.description,
                    characters: task.characters,
                    orderType: task.orderType,
                    copyRate: task.copyRate,
                    corectorRate: task.corectorRate,
                    fullRate: task.fullRate,
                    clientId: task.clientId._id,
                    copywriterId: task.copywriterId._id,
                    pmId: task.pmId._id,
                    korektorId: task.korektorId._id,
                    correctorSend: false,
                    date: formattedDate,
                    dateCp: task.dateCp,
                    status: statusName,
                    createdAt: task.createdAt,
                    createdBy: this.$store.getters.getUserName,
                    stopEmail: true
                }, { headers: { 'x-access-token': token } })
                .then((resp) => {
                    console.log(resp)
                    this.sendFile(resp.data.id);
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        getClient(){
            this.$axios.get(this.url + 'api/clients/' + this.$route.params.id, {
                headers: { 'x-access-token': token }
            }).then(resp => {
				this.clientInfo = resp.data
                this.description = this.clientInfo.client.comment
            })
		},
        changeRoute(id) {
            this.$router.push(`../task/${id}`);
        },
        addComment(){
            this.isLoading = true
            this.$axios.put(`${this.url}api/clients/comment`, {
                comment: this.description,
                id: this.$route.params.id
            }, { headers: { 'x-access-token': token } }).then(()=>{
                this.isLoading = false;
                this.modal = false
            }).catch(()=>{
                this.isLoaded = false;
            })
        },
        correctDate(date) {
            const newDate = new Date(date);
            return `${newDate.getHours()}:${
                newDate.getMinutes() < 9
                    ? "0" + newDate.getMinutes()
                    : newDate.getMinutes()
            }`;
        },
	},
    created(){
        this.getClient();

        this.socket.on("clientUpdated", () => {
            this.getClient();
        });

        this.socket.on("newTaskAdded", () => {
            this.getClient();
        });
    },
}
</script>

<style lang="scss">
.cursor-pointer{
    cursor: pointer;
}
</style>